footer {
  background: var(--gray-medium);
}

footer img,
footer img * {
  max-width: 7.5rem;
}

footer .social img,
footer .social img * {
  max-height: 1.875rem;
}

footer p {
  font-size: 1rem;
  line-height: 1.1875rem;
}

footer p.text-white a {
  color: var(--white);
  text-decoration: none;
}

footer .footer-logo {
  max-width: 7.625rem;
}
