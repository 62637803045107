@import url("./colours.css");

* {
  font-family: Lato, sans-serif;
}

body {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

h1,
h2 {
  font-family: Adam;
  font-weight: 900;
  letter-spacing: 1px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-100 {
  z-index: 100;
}

.btn-primary {
  background-color: var(--gray-medium);
  border-color: var(--gray-medium);
  line-height: 3rem;
  width: 12.375rem;
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--gray-medium);
  border-color: var(--gray-medium);
  box-shadow: 0 0 0 0.25rem var(--gray-medium-50);
}

.text-shadow {
  text-shadow: 1px 1px 0 var(--white);
}

.box-shadow {
  box-shadow: 0px 0px 5px var(--white);
}

.text-gray-medium,
.text-gray-medium:hover,
.text-gray-medium:active,
.text-gray-medium:focus {
  color: var(--gray-medium);
}

.transition {
  transition: 1s;
}

section {
  transition: 2s;
}

section.parallax,
section .sub-section {
  background-position: center;
  background-size: cover;
}

section {
  padding-top: 11rem;
}
