@media (min-width: 992px) {
  .bg-lg-transparent {
    background-color: transparent !important;
  }

  .shadow-lg-none {
    box-shadow: none !important;
  }

  .animation-lg-fade-in {
    transition: all linear 300ms;
  }

  .animation-lg-fade-out {
    padding: 0 !important;
    margin: 0 !important;
    height: 0;
  }

  .animation-lg-fade-out .social-navbar {
    position: absolute;
    left: 0;
    margin-top: 0.5rem;
    width: auto;
    z-index: 10;
  }

  .animation-lg-fade-out .language-navbar {
    position: absolute;
    right: 0;
    margin-top: 1rem;
    width: auto;
    z-index: 10;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1366px) {
  .navbar > .container {
    max-width: 700px;
  }

  .col.social-navbar a {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .offcanvas-body > ul.justify-content-evenly {
    justify-content: space-around !important;
  }

  #electrical div.d-lg-block {
    display: flex !important;
  }
}

@media only screen and (min-device-width: 1367px) and (max-device-width: 1800px) {
  .navbar > .container {
    max-width: 65%;
  }
}
