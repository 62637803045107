* {
  --white: #ffffff;
  --gray-medium: #353536;
  --gray-medium-50: rgb(53 53 54 / 50%);
  --gray-light: #4b4b4b;
  --gray-dark: #101010;
  --black: #000000;

  --card-shadow: rgb(151 151 151 / 50%);
}
